.chronology-main-div{
    width: 100%;
    display:flex;
    flex-direction:column;
    align-items: center;
    font-family: 'Nunito';
    margin-top:2rem;
    .heading-section{
        text-align: start;
        width:90%;
        .main-heading{
            font-size:4.3rem;
            color:#F4C2C2;
        }
    }
    .image-section{
        .main-image{
            margin:6rem;
        }
    }
    .ending-section{
        width:90%;
        text-align: start;
        color:white;
        .main-text{
            font-size:1.3rem;
            padding-bottom:10px;
            .make-bold{
                font-weight:bold;
            }
        }
    }
}