.nindhi-main-div {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
  .where-it-all-started-section {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .heading {
      padding-top: 3rem;
      color: #f4c2c2;
      font-size: 2.3rem;
      font-weight: bold;
      font-family: 'Nunito';
    }
  }
  .story-part {
    margin-top: 2rem;
    width: 80%;
    color: white;
    font-size: 1.2rem;
    text-align: center;
    .color {
      color: #f4c2c2;
    }
    .bold {
      font-weight: bold;
    }
  }

  .quote {
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 2rem;
    font-size: 2.3rem;
    .color {
      color: #f4c2c2;
    }
  }
  .group-1-image {
    margin-top: 5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
