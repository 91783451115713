.main-featured-cont {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .featured-wrapper {
    padding: 0rem 7rem;

    @media only screen and (max-width: 1200px) {
      padding: 0rem 5rem;
    }

    @media only screen and (max-width: 820px) {
      padding: 0rem 3rem;
    }

    @media only screen and (max-width: 520px) {
      padding: 0rem 1rem;
    }

    .header {
      padding: 4rem 0rem;

      .heading {
        font-size: 3rem;
        text-align: center;
        font-family: 'Nunito';
        font-weight: bold;
        color: #f4c2c2;
        @media only screen and (max-width: 1080px) {
          font-size: 2rem;
        }

        @media only screen and (max-width: 520px) {
          font-size: 1.5rem;
        }
      }
    }

    .main-body {
      // border: 2px solid white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .pro-1 {
        display: flex;
        flex-direction: column;
        width: 35rem;
        // margin-right: 3rem;
        cursor: pointer;

        .picture {
          width: 100%;
          border-radius: 10px;
          object-fit: cover;
        }

        .title {
          font-family: 'Nunito';
          font-size: 2.5rem;
          color: white;
          text-decoration: none;

          @media only screen and (max-width: 520px) {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  .all-projects {
    position: absolute;
    right: -5rem;
    top: 400%;
    font-size: 2.5rem;
    font-family: 'Nunito';
    color: #f4c2c2;
    cursor: pointer;

    @media screen and (max-width: 1000px) {
      display: none;
    }
  }
  .done-text-section {
    margin: 6rem 0;
    width: 100%;
    text-align: center;
    .main-text {
      color: white;
      font-size: 2.7rem;
      font-weight: bold;
      font-family: 'Nunito';
    }
  }
}
